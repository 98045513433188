import './css/font-awesome.min.css';
import './css/style.css';
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from 'axios';
import Testimony from './components/testimonia';
import React  from 'react'; 
import AuthHeaders from './components/AuthHeader';
import { AuthTextbox, AuthTextFlex, AuthTextgroup } from './components/componentStyles';

const Forgot = () => {
    const d = new Date();
    let year = d.getFullYear();

    const [email, setEmail] = useState('');
    const resetPassword = (e) => {
        e.preventDefault();
        axios.post('http://localhost:3001/forgot',{
           email: email
        }).then((response)=>{
                console.log(response)
        }).catch((error)=>{
            console.log(error)

        })
    }

    return ( 
<>
<AuthHeaders/>
    <div className="wrapper">
        <div className="container-fluid user-auth row">
        <div className="d-none d-sm-block col-sm-4 col-md-4 col-lg-4">
          
                <Testimony/>
			</div>
			<div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 ">
			
				<div className="form-container">
					<div>
						<div className="row text-center">
							<h2 className="title-head hidden-xs">Forgot <span className='text-warning'>Password</span></h2>
							 <p className="info-form">Enetr Your user Email to reset your password</p>
						</div>
						
						<form onSubmit={resetPassword}>
						<AuthTextgroup className="mb-4">
								<AuthTextFlex>
								<AuthTextbox  name="email" id="txt_uname" placeholder="EMAIL" type="email" value={email} onChange={(e)=> setEmail(e.target.value)} required />
							</AuthTextFlex>
							</AuthTextgroup>
						
							<div className="form-group">
								<button className="btn btn-warning" name="reset" type="submit">Reset</button>
								<p className="text-center " ><Link to="/login" className=' text-warning'>Login</Link> | don't have an account ? <Link to="/register" className=' text-warning'>register now</Link></p>
							</div>
								{/* <div className='mt-10'>{resetResponse}</div> */}
						</form>
					
					</div>
				</div>
				
				<p className="text-center copyright-text">Copyright © {year} Beta Options All Rights Reserved</p>
				
			</div>
		</div>
      

    </div>
	{/* Wrapper Ends  */}



</>

     );
}
 
export default Forgot;