import styled from 'styled-components';

export const ButtonLg= styled.button`
padding: 15px 30px;
font-size: 18px !important;
color: #1e1f23;
    font-weight: 600;
    background-color: #ffc107;
    border-color: #ffc107;
    cursor: pointer;
    line-height: 14px;
    position: relative;
    transition: .1s;
    text-transform: none;
    margin: 0 auto;
    border-radius: 0.25rem;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    display: flex;
    align-items: center;
    :hover{
        background-color: rgb(255 201 67);
    color: #181a20;
    }
    :focus{
        box-shadow: 0 0 3px 0px #fdeab0;
        color: #181a20;
    }
    i{
        margin-right:5px
    }
    @media screen and (min-width:768px){
        margin: 0;
    }
    `

 export const H2 = styled.h2`
 color: var(--font-color);
font-size: 32px;
font-weight: 700;
line-height: 1.5;
max-width: 100%;
@media (min-width: 768px)
 {
    font-size: 40px;
    line-height: 1.33;
} 
@media (min-width: 1023px)
 {
    font-size: 44px;
}
 `
    export const H3 = styled.h3`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: var(--font-color);
    margin-bottom: 8px;
    @media screen and (min-width: 767px)
 {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
}

    `
    export const Text = styled.div`

    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    color: var(--text);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    @media screen and (min-width: 767px) {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
    }
    
`
export const Text2 = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    min-height: 1.2em;`
 export const Paragraph = styled.div`
 box-sizing: border-box;
 margin: 0;
 min-width: 0;`
    export const ParagraphText = styled.div`
      box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
    color: var(--font-color); 
    @media screen and (min-width: 767px)
{
     font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 24px;
}
    `
export const ParagraphList = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;

    @media screen and (min-width: 767px)
 {
        flex-direction: row;
        margin-bottom: 64px;
    }`
export const ParagraphListImgWrapper = styled.div`

    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 16px;
    @media screen and (min-width: 767px)
{
    justify-content: center;
    margin-bottom: 0;
}`
export const ParagraphListImg = styled.i`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
   font-size: 48px;
   color: var(--warning);

@media screen and (min-width: 767px)
 {
    font-size: 72px;
}
`
export const ParagraphListItem = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-left: 0;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
@media screen and (min-width: 767px)
 {
    margin-left: 24px;
}
@media screen and (min-width: 1023px)
 {
    margin-left: 40px;
}

`
    export const IndexSection = styled.div`
    
        box-sizing: border-box;
        margin: 0;
        min-width: 0;
        padding-top: 40px;
        padding-bottom: 40px;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border-radius: 24px 24px 0 0;
        z-index: 2;
       
      @media screen and (min-width: 767px){
       
        padding-top: 45px;
        padding-bottom: 45px;
     
    } 
    @media screen and (min-width: 1023px){

        padding-top: 50px;
        padding-bottom: 50px;
    
    }
    `
    export const IndexSectionHelpSingle = styled(IndexSection)`
    @media screen and (min-width: 1023px){
        width: 80vw;
    }
    `
    export const IndexSectionWrapper = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    margin-left: auto;
    margin-right: auto;
    padding-left: 16px;
    padding-right: 16px;
    max-width: 1248px;
    @media screen and (min-width: 767px)
{
    padding-left: 24px;
    padding-right: 24px;
}
    `
    export const IndexSectionDescription = styled.div`
     color: var(--font-color);
        font-size: 20px;
        font-weight: 400;
        line-height: 1.7;
        margin: 0 0 16px; 

        @media (min-width: 768px){
        font-size: 21px;
    } 
    @media (min-width: 1100px){
       
        font-size: 24px;
    }
    `
  export const ColumnRow = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 767px){
    flex-direction: row;
    }`
    export const RowColumn = styled.div`
    display: flex;
    flex-direction: row;
    @media screen and (min-width: 767px){
      flex-direction: column;
      }`
    export const Column = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    `
      export const Row = styled.div`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        `
    export const Wrap = styled.div`
    flex-wrap: wrap;`
    export const ColumnItem1 = styled.div`
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;

    @media screen and (min-width: 767px)
    {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
    }
    @media screen and (min-width: 1023px)
{
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
}
`
    export const ColumnItem2 = styled.div`

    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    text-align: center;
    margin-bottom: 40px;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;

    @media screen and (min-width: 767px)
    {
       -webkit-order: 1;
       -ms-flex-order: 1;
       order: 1;
       text-align: center;
   }
@media screen and (min-width: 1023px)
{
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    text-align: center;
    margin-bottom: 0;
}
.mica-lazy-img {
    position: relative;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.03);
}
`
export const LargeImage = styled.img`
box-sizing: border-box;
    margin: 0;
    min-width: 0;
    width: 190px;
    background-color: transparent;
    @media screen and (min-width: 767px)
 {
    width: 190px;
}
@media screen and (min-width: 1023px)
{
    width: 335px;
}

    
`
export const Card = styled.div`

    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    width: 100%;
    padding: 8px;
    position: relative;
    @media screen and (min-width: 767px)
    {
        width: calc(50% - 16px);
        padding: 16px;
    }
   
    `
    export const CardSM =styled(Card)`
    @media screen and (min-width: 767px)
    {
        width: calc(25% - 16px);
        padding: 16px;
    }
    width: 50%;
    `
    export const CardLG =styled(Card)`
   
    width: 100%;
    `
    export const CardInnerTransparency =styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    padding: 0px 16px;
    width: 100%
    `
   export const CardInner = styled.div`
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        box-shadow: var(--card-shadow);
        border-radius: 4px;
        background-color: var(--card-background-color);
        padding: 0px 16px;
        width: 100%;
        
        :hover{
            box-shadow: var(--card-shadow-hover);
            background-color: var(--card-background-color-hover);
            transition: ease-in 0.2s;
            color: var(--warning);
            cursor: pointer;
          
        }
    `
    
export const CardContent = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex: 1 1 0%;
    flex-direction: column;
    a{
        color:rgb(201, 148, 0)
}
`
export const CardBody = styled.div`
    box-sizing: border-box;
    margin: 0px 0px 10px 0px;
    min-width: 0px;
    position: relative;
    
`
    
export const CardHeader = styled.div`
    box-sizing: border-box;
     margin: 0px;
     min-width: 0px;
     flex: 1 1 0%;
     padding: 16px 0;
     border: 0;
     display: flex;
 `
export const CardHeaderText = styled.div`
    box-sizing: border-box;
    margin: 0px 16px 0px 0px;
    min-width: 0px;
    display: flex;
    flex: 1 1;
    text-decoration: none;
    font-weight: 700;
    font-size: 14px;
    padding: 0px;

@media screen and (min-width: 767px)
{
   
    font-size: 16px;


}`

export const ButtonWrapper = styled.div`
align-items: center;
    justify-content: center;
    margin-top: 20px;
    display: flex;`

    export const TextIconWrapper = styled.div`
    a{
         box-sizing: border-box;
    margin: 16px 0px 0px;
    min-width: 0px;
    display: flex;
    color: rgb(201, 148, 0);
    -webkit-box-align: center;
    align-items: center;
    }
   
`
export const TextIcon = styled.i`
box-sizing: border-box;
margin: 0px 0px 0px 4px;
min-width: 0px;
color: currentcolor;
font-size: 24px;
fill: currentcolor;
width: 1em;
height: 1em;
`

export const Table = styled.table`
background-color:var(--table-background-color);
box-sizing: border-box;
min-width: 0px;
color: var(--font-color);
border-collapse: inherit;
border-radius:10px`

export const Tr = styled.tr`
margin: 10px 0px;
padding: 10px;
border-radius: 10px;
:hover{
background-color:var(--table-row-hover);
}
`
export const Td = styled.td`
border-width: 0px !important;`

export const Half = styled.div`
width:50%`

export const Textgroup = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 100%;
position: relative;
min-height: 12px;
`
export const IconDiv = styled.div`
box-sizing: border-box;
    margin: 0;
    min-width: 0;`
export const TextFlex = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
display: inline-flex;
position: relative;
align-items: center;
line-height: 1.6;
border: 1px solid rgb(71, 77, 87);
border-radius: 4px;
height: 48px;
background-color: transparent;
width: 100%;
input{
    color: var(--font-color);;
    font-size: 14px;
    padding-left: 12px;
    padding-right: 12px;
}
input:-webkit-autofill{
    transition: color 99999s ease-in-out 0s, background-color 99999s ease-in-out 0s;
    -webkit-text-fill-color: var(--font-color);;
}
:hover{
    border-color: rgb(240, 185, 11);
}
${IconDiv}{
    flex-shrink: 0;
    margin-left: 4px;
    margin-right: 4px;
    font-size: 14px;
}
.PhoneInput{ 
    width: 100%;
    padding: 0 10px;
}
.PhoneInputInput{
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    width: 100%;
    height: 100%;
    padding: 0px;
    outline: none;
    border: none;
    background-color: inherit;
    opacity: 1;
}`

export const IconBody = styled.div`
box-sizing: border-box;
    margin: 0px 4px 0px 0px;
    min-width: 0px;
    display: flex;
    align-items: center;`
export const Icon = styled.i`
box-sizing: border-box;
    margin: 0px 0px 0px 4px;
    min-width: 0px;
    color: rgb(94,102,115);
    font-size: 20px;
    width: 1em;
    height: 1em;`
export const Textbox = styled.input`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 100%;
height: 100%;
padding: 0px;
outline: none;
border: none;
background-color: inherit;
opacity: 1;
`
export const SearchResult = styled(IndexSectionDescription)`
@media screen and (min-width:767px){
  font-size:16px  
}
font-size:14px

`