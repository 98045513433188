import './css/font-awesome.min.css';
import './css/style.css'
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from 'axios';
import  './css/loadProgressMin.css'
import React  from 'react';
import AuthHeaders from './components/AuthHeader';
import { AuthContainer, AuthTextbox, AuthTextFlex, AuthTextgroup, FlexColumn, Icon, IconBody, IconDiv } from './components/componentStyles';
import Page404 from './components/404';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'



const ContinueRegister = () => {
	var item = useLocation();
	document.title = `Beta Options | Registration`;
    const userId = item.state.userId

    const [first, setFirst]= useState('');
    const [last, setLast]= useState('');
    const [phone,setPhone]= useState();
    const [password, setPassword]= useState('');
	const [regBtn, setRegBtn]= useState(<button className="btn btn-warning" type="submit">Proceed</button>);
	const [regResponse, setRegResponse] = useState('');
	const [passwordShown, setPasswordShown] = useState(false);
	const navigate = useNavigate();
	
    const registerUser = (e)=> {
        e.preventDefault();
		setRegBtn(<button className="btn btn-warning" type="submit" disabled={true}>
					<div className="lds-ring"><div></div><div></div><div></div><div></div></div></button>)
		let newPhone = phone.replace(/\s+/g, '');
	   const payload = {
		userId: userId,
		firstName: first,
		lastName: last,
		phoneNumber: newPhone,
		password:password 
	}
        axios.post('https://api.betaoptionss.com/user/addBios',payload).then((response)=>{
			navigate('/currency', {state: {userId:userId}})
		  
        }).catch((error)=>{
			const message = error.response.data
			setRegBtn(<button className="btn btn-warning" type="submit">Proceed</button>)
			message.forEach(item => {
				setRegResponse(<div className='alert alert-danger p-2 alert-dismissible fade show' role="alert">{item}</div>);

			});
			
           
        })
    }
	if (item.state === null) {
		return (<>
			<Page404 />
		</>);
	}
	else {
		
		const hideState = <Icon className="fi fi-sr-eye"></Icon>
	const showState = <Icon className="fi fi-sr-eye-crossed"></Icon>
	const togglePassword = () => {
		setPasswordShown(!passwordShown);
	  };
    return ( 
        
        <>
<AuthHeaders/>
 <div className="wrapper">
        <div className="container-fluid">
		
			<AuthContainer>
				<div className="form-container">
					<div>
						
						<div className="row text-center">
							<h2 className="title-head hidden-xs">User <span className='text-warning'>Detials</span></h2>
							 <p className="info-form">Kindly ensure all information entered are correct!</p>
						</div>
						
						<form onSubmit={registerUser}>
						<div className='mt-10'>{regResponse}</div>

                        <AuthTextgroup className="mb-4">
							<AuthTextFlex>
								<AuthTextbox  placeholder="FIRSTNAME" type="text" required value={first} onChange={(e)=> setFirst(e.target.value)}/>
								</AuthTextFlex>
							</AuthTextgroup>
						
							<AuthTextgroup className="mb-4">
                                <AuthTextFlex>
								<AuthTextbox  placeholder="LASTNAME" type="text" required value={last} onChange={(e)=> setLast(e.target.value)}/>
							</AuthTextFlex>
							</AuthTextgroup>
						
							<AuthTextgroup className="mb-4">
                                <AuthTextFlex>
								<PhoneInput   placeholder="PHONE IN INTERNATIONAL FORMAT (eg. +1)" value={phone} onChange={setPhone}/>
							</AuthTextFlex>
							</AuthTextgroup>
							<AuthTextgroup className="mb-4">
							<AuthTextFlex>
								<AuthTextbox  name="password" id="txt_pwd" placeholder="PASSWORD" type={passwordShown ? "text" : "password"} value={password} onChange={(e)=> setPassword(e.target.value)} required
								/>
								<IconDiv className="icon-div">
								<IconBody  id="pass-display" onClick={togglePassword}>{passwordShown ? showState : hideState}</IconBody>
								</IconDiv>
								</AuthTextFlex>
							</AuthTextgroup>
							<AuthTextgroup>
								<FlexColumn>
									{regBtn}
								</FlexColumn>
								
								<p className="text-center"><Link to="/" className=' text-warning'>Back to Home</Link> | already have an account ? <Link to="/login" className=' text-warning'>Login instead</Link>  </p>
								<br/>
								</AuthTextgroup>
							
						</form>
						
					</div>
					
				</div>
				
			
			</AuthContainer>
		</div>
	

    </div>
        </>
     );
	}
}
 
export default ContinueRegister;