import styled from 'styled-components';

export const Card = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
display: flex;
width: 100%;
padding: 8px;
`
    export const CardHeader = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    flex: 1 1;
    padding-top: 16px;
    padding-bottom: 16px;
    border: 0;`

    export const CardHeaderContent =  styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    font-size: 12px;
    line-height: 16px;  
    width:75%;

    @media screen and (min-width: 767px)
{
    font-size: 16px;
    line-height: 24px;
}`
export const NewsImage = styled.img`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
width: 32px;
height: 32px;
flex-shrink: 0;
border-radius: 100%;
`
export const NewsDiv = styled.div`
box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    align-items: center;
    height: 52px;`
export const NewsHeader = styled.div`

    box-sizing: border-box;
    margin: 0px 8px 0px 0px;
    min-width: 0px;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: var(--font-color);
    
    @media screen and (min-width: 1023px)
{
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
}

@media screen and (min-width: 767px)
 {
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
}
`
export const NewsContent = styled.div`
box-sizing: border-box;
    margin: 0px 0px 0px 24px;
    min-width: 0px;
    display: flex;
    height: 64px;
    justify-content: center;
    flex-direction: column;`

    export const NewsBody = styled.div`
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    color: rgb(112, 122, 138);`

    export const NotFound = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10% auto;
    color: #d5d5d5;
`
export const NotFoundIcon = styled.i`
font-size: 40px;

`
export const NotFoundText = styled.div`
font-size: 16px;
padding: 10px;
`
export const FlexColumn = styled.div`
display: flex;
flex-direction: column;`

export const FlexRow = styled.div`
display: flex;
flex-direction: row;`

export const BUY = styled.div`    
margin-right: 5px;
color: green;
font-weight: 700;`

export const SELL = styled.div`
margin-right: 5px;
color: red;
font-weight: 700;`
export const TradePrice = styled.div`
font-size: 10px;
line-height: 0.1;`
export const Table = styled.table`
background-color:var(--table-background-color);
box-sizing: border-box;
min-width: 0px;
color: var(--font-color);
border-collapse: inherit;
border-radius:10px`

export const Tr = styled.tr`
margin: 10px 0px;
padding: 10px;
border-radius: 10px;
:hover{
background-color:var(--table-row-hover);
}
`
export const Td = styled.td`
border-width: 0px !important;`
export const CardContent = styled.div`
@media screen and (min-width: 767px)
{
    align-items: flex-start;
}
    box-sizing: border-box;
    margin: 0px 0px 12px 0;
    min-width: 0px;
    display: flex;
    flex-direction:column;
    flex-wrap: wrap;`
    export const TextList = styled.div`
display:flex;
flex-direction:row;
color: rgb(112, 122, 138);`
export const TextListIcon = styled.div`
display:block;
`
export const TextListItem = styled.div`
margin-left:10px;
line-height:1.75;
font-weight:700
`
export const Clearfix = styled.div`
box-sizing: border-box;
margin: 0px;
min-width: 0px;
min-height: 20px;
}`
export const YellowButton = styled.button`
@media screen and (min-width: 767px)
{
    min-width: 120px;
}

    margin: 0px;
    
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    font-family: inherit;
    text-align: center;
    text-decoration: none;
    outline: none;
    padding: 10px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    word-break: keep-all;
    color: rgb(33, 40, 51);
    border-radius: 4px;
    min-height: 24px;
    border: none;
    background-image: none;
    background-color: #ffc107;
    height: 40px;
    min-width: 100%;

    :hover {
        background-color: #ffcb2d;
        transition:cubic-bezier(0.6, -0.28, 0.74, 0.05) 0.5s;
    }
`
export const YellowButtonLink =  styled.button`
background: none;
    border: none;
    outline: none;
    color: #ffc107;
hover:{
   color: #ffd042
}`