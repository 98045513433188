import React, { useEffect, useState } from 'react';
import styles from '../../style/main.module.css'
import AccountBalance from './components/mainBalance';
import UserCoins from './components/myCoins';
import AllCoins from './spot/allcoin';
import FiatWallet from './spot/fiatWallet';
const SpotWallet = () => {
    const [currency, setCurrency] = useState()
    const [currencySymbols, setCurrencySymbols] = useState()
    const [userId, setUserId] = useState()
    const [AccountShown, setAccountShown] = useState(false);
    const [AccountFiatShown, setAccountFiatShown] = useState(false);
    var userCoin = []

   const userCoinList =(data)=>{
    userCoin.push(data)
        }
    useEffect(() => {
         
        const userInfo = localStorage.getItem('_currentInfo')
        const users = JSON.parse(userInfo)
        const userCurrency = localStorage.getItem('OMS__CUR')
        const curr = JSON.parse(userCurrency)
        setCurrency(curr.name)

        setCurrencySymbols(curr.symbol)
        setUserId(users.user_id)
    }, [])
    const hideState = <i className="fi fi-sr-eye"></i>
	const showState = <i className="fi fi-sr-eye-crossed"></i>
	const toggleAccount = () => {
		setAccountShown(!AccountShown);
	  };
      const hideFiatState = <i className="fi fi-sr-eye"></i>
      const showFiatState = <i className="fi fi-sr-eye-crossed"></i>
      const toggleFiatAccount = () => {
          setAccountFiatShown(!AccountFiatShown);
        };
        
    return (<>
        <div className={styles.main_container}>
            <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>
                        <div className={`h2 ${styles.heading_text}`}>
                            Spot Wallet
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.dashboard_table}>
                <div className={styles.balance_row_wrapper}>
                       <div className={styles.balances_rows}>
                    <div className={`${styles.card} ${styles.half}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={` ${styles.balance} ${styles.coin_title} h5`}>Coin Balance</div>
                                    <div className={`  ${styles.coin_changes}`} style={{ direction: "ltr", lineHeight: "2" }} onClick={toggleAccount}>{AccountShown ? hideState : showState } </div>
                                </div>
                            </div>
                            <div className={styles.card_body}>
                                <div className={styles.balance_body}>
                                {AccountShown ? <> <div className={styles.main_balance}>****** *****</div></> : <AccountBalance id={userId} currency={currencySymbols} fiat={currency} />}
                                    
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={`${styles.card} ${styles.half} `}>
                    <div className={` ${styles.card_inner} ${styles.fiat_balance_body}`}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={styles.coin_id}>
                                    <div className={` ${styles.balance} ${styles.coin_title} h5`}>Fiat Balance</div>
                                    <div className={`  ${styles.coin_changes}`} style={{ direction: "ltr", lineHeight: "2" }} onClick={toggleFiatAccount}>{AccountFiatShown ? hideFiatState : showFiatState } </div> 
                                     </div>
                            </div>
                            <div className={styles.card_body}>
                                <div className={styles.balance_body}>
                                <FiatWallet showFiat={AccountFiatShown}/>

                                   
                                </div>
                            </div>

                        </div>
                    </div>
                </div> 
                </div>
                </div>
             
               
                <div className={`${styles.card} ${styles.lg}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={` ${styles.balance} ${styles.coin_title} h5`}>My Wallets</div>
                            </div>
                            <div className={styles.card_body}>
                                {userId && <UserCoins userId={userId}  list={userCoinList}/>}

                            </div>
                        </div>
                    </div>

                </div>
                <div className={`${styles.card} ${styles.lg}`}>
                    <div className={styles.card_inner}>
                        <div className={styles.card_content}>
                            <div className={styles.card_header}>
                                <div className={` ${styles.balance} ${styles.coin_title} h5`}>Spot Wallets</div>
                            </div>
                            <div className={styles.card_body}>
                                {userId && <AllCoins  userCoin={userCoin} userId={userId} currencySymbol={currencySymbols} currency={currency}/>}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </>);
}

export default SpotWallet;