import first_child from '../../images/backgrounds/bg-user.jpg'
import second_child from '../../images/backgrounds/bg-user-2.jpg'
import third_child from '../../images/backgrounds/bg-user-3.jpg'
import Carousel from 'react-bootstrap/Carousel'
import React  from 'react';

const Testimony = () => {
    return ( 
        <>
<Carousel fade  id="carousel-testimonials">
				<Carousel.Item>
					<img className="d-block w-100" src={first_child}	alt="First slide"
					/>
					<Carousel.Caption>
					<blockquote>
					<p>This is a realistic program for anyone looking for site to invest. Paid to me regularly, keep up good work!</p>
					<footer><span className="text-warning">Lucy Smith</span>, England</footer>
					</blockquote>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img className="d-block w-100" src={second_child}	alt="Second slide"
					/>
					<Carousel.Caption>
					<blockquote>
					<p>Bitcoin doubled in 7 days. You should not expect anything more. Excellent customer service!</p>
					<footer><span className="text-warning">Slim Hamdi</span>, Tunisia</footer>
				</blockquote>
					</Carousel.Caption>
				</Carousel.Item>
				<Carousel.Item>
					<img className="d-block w-100" src={third_child}	alt="Third slide"
					/>
					<Carousel.Caption>
					<blockquote>
									<p>My family and me want to thank you for helping us find a great opportunity to make money online. Very happy with how things are going!</p>
									<footer><span className="text-warning">Rawia Chniti</span>, Russia</footer>
								</blockquote>
					</Carousel.Caption>
				</Carousel.Item>
				</Carousel>
	

        </>
     );
}
 
export default Testimony;