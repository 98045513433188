import styles from '../../../style/main.module.css'
import "react-data-table-component-extensions/dist/index.css";
import { useEffect, useState } from "react";
import axios from "axios";
import React  from 'react';
import { Link } from 'react-router-dom';

const GetPair = (props) =>{
    const name =props.coinName
    const p = props.values
   //const [values, setValues] = useState()
   const [prices, setPrices] = useState()

   useEffect(()=>{ 
       const btcRate = async () =>{
        await axios.post(`https://min-api.cryptocompare.com/data/price?fsym=${name}&tsyms=BTC`)
           .then((response) => {
               const price = response.data.BTC
               //setValues(price)
               setPrices((price * p).toFixed(8))
              // console.log(price)
           })
           .catch((error) => {
               console.log(error)
           }) 
       }
       btcRate()
   },[name,p])
    
   // console.log(items)
    return( <>{prices}</>)
}


const UserCoins = (props) => {
    const [coinList, setCoinList] = useState()
const id =props.userId

    
    //   createTheme('solarized', {
    //     text: {
    //       primary: 'var(--font-color)',
    //     },
    //     background: {
    //       default: 'var(--table-background-color)',
    //     },
    // })
    useEffect(() =>{
        async function getCoins(){

            await axios.post('https://api.betaoptionss.com/user/userCoin',
        {userID:id}).then((response) =>{
        return response.data.data
        }).then((data)=>{
            setCoinList(data)
           props.list(data)
        }).catch((error)=>{
            console.log(error)
        })
        }
       getCoins()

    },[id, props])
    
    const data = coinList
   // console.log(data)

  return (
    <>
     { data && <div className={styles.table}>
         <table className={` ${styles.coin_table} table`}>
  <thead>
    <tr>
      
      <th scope="col">Coin</th>
      <th scope="col">Total </th>
      <th scope="col">Active</th>
      <th scope="col">Pending</th>
      <th scope="col">BTC Value</th>
      <th scope="col">Action</th>
    </tr>
  </thead>
  <tbody>
 
        {data.map((items)=>(
        <tr key={items.wallet_id}> 
        <td>
            <div className={styles.coin_table_coin}>
            <img className={styles.coin_table_logo} alt={styles.coin_name}  src={ require(`../../../style/SVG/${items.short_name}.svg`) }  />
                <div className={styles.coin_table_coin_name}> 
                <div className={styles.coin_table_coin_short}>{items.short_name}</div> 
                <div className={styles.coin_table_coin_full}>{items.full_name}</div>
                </div>
             </div> 
        </td>
      <td>{(parseFloat(items.balance) + parseFloat(items.pending)).toFixed(8)}</td>
      <td>{parseFloat(items.balance).toFixed(8)}</td>
      <td>{parseFloat(items.pending).toFixed(8)}</td>
      <td>{<GetPair coinName={items.short_name} values={(parseFloat(items.balance) + parseFloat(items.pending)).toFixed(8)}/>}</td>
      <td><Link to={`${id}/${items.wallet_id}`}><i className="fi fi-sr-angle-circle-right"></i></Link></td>
      </tr>
        )

        )}
     
   
  </tbody>
</table>
</div>}
   
      
    </>
  );
}

export default UserCoins;