import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from '../../style/main.module.css'
import UserCoins from './components/myCoins';
import DepositCoin from './components/deposit';
import AccountBalance from './components/mainBalance';
import RecieveCoin from './components/recieve';
import SwapCoin from './components/swap';
import React  from 'react';

const Wallet = () => {
    const [modalType, setModalType] = useState()
    const [currency, setCurrency] = useState()
    const [currencySymbols, setCurrencySymbols] = useState()
    const [userId, setUserId] = useState()
    const [modalInfo, setModalInfo] = useState()
    const [AccountFiatShown, setAccountFiatShown] = useState(false);
    useEffect(()=>{

    const userInfo = localStorage.getItem('_currentInfo')
    const users = JSON.parse(userInfo)
        const userCurrency = localStorage.getItem('OMS__CUR')
           const curr = JSON.parse(userCurrency)
      setCurrency(curr.name)
      setCurrencySymbols(curr.symbol)
      setUserId(users.user_id) 
        },[])

        const OpenModal = (e) =>{
            e.preventDefault()
            const modalType = e.target.id
            setModalType(modalType)
            if (modalType === 'Deposit') {
                
                setModalInfo(<DepositCoin id={userId} userFiat={currency} currencySym={currencySymbols}/>)
              }
              else if (modalType === 'Recieve') {
    
                setModalInfo(<RecieveCoin id={userId} userFiat={currency} currencySym={currencySymbols}/>)
              }
              else if (modalType === 'Swap') {
            
                setModalInfo(<SwapCoin id={userId} userFiat={currency} currencySym={currencySymbols}/>)
              }
        }
        const hideFiatState = <i className="fi fi-sr-eye"></i>
        const showFiatState = <i className="fi fi-sr-eye-crossed"></i>
        const toggleFiatAccount = () => {
            setAccountFiatShown(!AccountFiatShown);
          };
    return ( <> 
    <div className={styles.main_container}>
    <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>
                        <div className={`h2 ${styles.heading_text}`}>
                             Wallet Details
                        </div>
                    </div> 
                </div>
    </div>
     <div className={styles.dashboard_table}>
            <div className={`${styles.card} ${styles.md}`}>
                <div className={styles.card_inner}>
                    <div className={styles.card_content}>
                       <div className={styles.card_header}>
                       <div className={styles.coin_id}>
                            <div className={` ${styles.balance} ${styles.coin_title} h5`}>Total Balance</div> 
                            <div className={`  ${styles.coin_changes}`} style={{ direction: "ltr", lineHeight: "2" }} onClick={toggleFiatAccount}>{AccountFiatShown ? hideFiatState : showFiatState } </div> 
                            </div>
                            </div> 
                            <div className={styles.card_body}>
                                <div className={styles.balance_body}>
                                {AccountFiatShown ? <> <div className={styles.main_balance}>****** *****</div></> :    <AccountBalance id={userId} currency={currencySymbols} fiat={currency} />}

                          
                            </div>
                            </div>
                            <div className={styles.card_header}>
                            <div className={styles.card_header_text}>All Coin</div> 
                            <Link className={styles.note_last} to="spot">
                                <div className={styles.note_last_item}>Add Coin</div>
                                <div className={styles.note_last_item_icon}> 
                                <i className="fi fi-rr-add"></i>
                                </div></Link>
                            </div>
                            

                            <div className={styles.card_body}>
                                {userId && <UserCoins userId={userId} />}
                            </div>
                    </div>
                </div>
            </div>
            <div className={`${styles.card} ${styles.sm}`}>
                <div className={styles.card_inner}>
                    <div className={styles.card_content}>
                       <div className={styles.card_header}>
                       <div className={styles.coin_id}>
                            <div className={` ${styles.balance} ${styles.coin_title} h5`}>Fund Balance</div> 
                            
                            </div><div className={`${styles.coin_title_sub}`}>We accept multiple methods to fund your account.</div>
                        </div>
                            <div className={styles.card_body}>
                                <div className={styles.balance_body}>
                                    <div className={styles.main_deposit}>
                                   <div className={styles.card_list}>
                                            <div className={styles.display_flex}>
                                                <div className={styles.card_list_lft}>
                                                    <i className={`fi fi-sr-credit-card ${styles.card_list_inner_icon}`}></i>
                                                </div>
                                                <div className={styles.card_list_ctr}>
                                                    <div className={styles.card_list_ctr_title}>Deposit Coin</div>
                                                    <div className={styles.card_list_ctr_info}>
                                                        Add coin to any of your wallet or request a new wallet
                                                    </div>
                                                </div>
                                            </div>
                                   
                                       <button data-bs-toggle="modal" data-bs-target="#fundAccountModal" className={` ${styles.wallet_btn} btn btn-warning btn-rounded`}
                                        id="Deposit" onClick={OpenModal}>Deposit</button>
                                    </div> 
                                    <div className={styles.card_list}>
                                            <div className={styles.display_flex}>
                                                <div className={styles.card_list_lft}>
                                                    <i className={`fi fi-sr-gift ${styles.card_list_inner_icon}`}></i>
                                                </div>
                                                <div className={styles.card_list_ctr}>
                                                    <div className={styles.card_list_ctr_title}>Recieve Coin</div>
                                                    <div className={styles.card_list_ctr_info}>
                                                        Copy and share wallet address to merchants and senders to recieve even when Offline
                                                    </div>
                                                </div>
                                            </div>
                                   
                                       <button data-bs-toggle="modal" data-bs-target="#fundAccountModal" className={` ${styles.wallet_btn} btn btn-warning btn-rounded`} 
                                      id="Recieve" onClick={OpenModal}>Recieve</button>
                                    </div> 
                                    <div className={styles.card_list}>
                                            <div className={styles.display_flex}>
                                                <div className={styles.card_list_lft}>
                                                    <i className={`fi fi-sr-shuffle ${styles.card_list_inner_icon}`}></i>
                                                </div>
                                                <div className={styles.card_list_ctr}>
                                                    <div className={styles.card_list_ctr_title}>Swap Coin</div>
                                                    <div className={styles.card_list_ctr_info}>
                                                        Swap Your coin withen your Beta options available coin wallet faster and convenient
                                                    </div>
                                                </div>
                                            </div>
                                   
                                       <button data-bs-toggle="modal" data-bs-target="#fundAccountModal" className={` ${styles.wallet_btn} btn btn-warning btn-rounded`}
                                        id="Swap" onClick={OpenModal}>Swap</button>
                                    </div> 
                                    </div>

                                </div>
                            </div>
                    </div>
                </div>
            </div>
    </div>
    </div>
<div className="modal fade" id="fundAccountModal" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className= "modal-dialog modal-dialog-centered modal-md">
    <div className={`  ${styles.card_inner} modal-content `}>
    <div className={`${styles.card_header} modal-header`}>
        <div className={`  ${styles.card_header_text}`} id="fundAccountModalLabel">{modalType} Coin</div>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> 
      <div className={`${styles.card_body} modal-body`}>
          {
             modalInfo
               
          }
      
      </div>
    </div>
  </div>
</div>
    
    </> );
}
 
export default Wallet;