import styles from '../../../style/main.module.css'
import { GetRandomString } from './random'
import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import CurrencyFormat from 'react-currency-format'
import '../../../../auth/css/loadProgressMin.css'
import { QRCode } from 'react-qrcode-logo'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { FlexRow, H2, TextLabel } from './bodyStyle'




class DepositCoin extends React.Component {
    state = {
        transProcessed: false,
        processedData: [],
        value: '',
        copied: false,
    }
    render() {
        const DepositModal = (props) => {
            const id = props.item.id
            const Fiat = props.item.userFiat
            const currSym = props.item.currencySym
            const [coin, setCoin] = useState('BTC')
            const [coinEquv, setCoinEquv] = useState(0)
            const [coinList, setCoinList] = useState()
            const [mainCur, setMainCur] = useState(0)
            const [coinWallet, setCoinWallet] = useState()
            const [walletId, setWalletId] = useState()
            const [coinFullname, setCoinFullname] = useState('bitcoin')
            const [proceedBtn, setProceedBtn] = useState('Proceed')
            useEffect(() => {
                const fetchCoin = (async () => {
                    await axios.post('https://api.betaoptionss.com/user/userCoin',
                        { userID: id })
                        .then((response) => {
                            return response.data.data
                        }).then((data) => {
                            setCoinList(data)
                            setCoinWallet(data[0].walletAddress)
                            setWalletId(data[0].wallet_id)
                            setCoin(data[0].short_name)
                            setCoinFullname(data[0].full_name)
                        }).catch((error) => {
                            console.log(error)
                        })

                })
                fetchCoin()
            }, [id])

            const getEquiv = (e) => {
                e.preventDefault();
                const init = Number(e.target.value)
                axios.post(`https://min-api.cryptocompare.com/data/price?fsym=${Fiat}&tsyms=${coin}`)
                    .then((response) => {

                        const price = response.data
                        const newPrice = JSON.stringify(price)
                        let strip = newPrice.replace(/{|}|"|:|A|B|C|D|E|F|G|H|I|J|K|L|M|N|O|P|Q|R|S|T|U|V|W|X|Y|Z/gm, '');
                        const newCryptoRate = (init * Number(strip)).toFixed(8)
                        setMainCur(init)
                        setCoinEquv(newCryptoRate)
                    }).catch((error) => {
                        console.log(error)
                    })
            }

            const data = coinList

            const GenerateTransaction = (e) => {
                e.preventDefault();

                const t_id = GetRandomString(8)
                const trans_data = {
                    trans_id: t_id,
                    amount: mainCur,
                    equv: coinEquv,
                    coinType: coin,
                    fiat: Fiat,
                    userId: id,
                    syb: currSym,
                    wallet: coinWallet,
                    coinFull: coinFullname,
                    openDate: Math.floor(Date.now() / 1000),
                    closeDate: Math.floor((Date.now() / 1000) + 600),
                }
                setProceedBtn(<div className="lds-ring"><div></div><div></div><div></div><div></div></div>)
                axios.post('https://api.betaoptionss.com/user/createTransaction',
                    {
                        trans_id:  t_id,
                        amount: mainCur,
                        equv: coinEquv,
                        coinType: coin,
                        fiat: Fiat,
                        userId: id,
                        trans_type: 'deposit',
                        date: Math.floor(Date.now() / 1000),
                        status: "pending",
                        walletId: walletId
                    })
                    .then((response) => {
                        return response.data
                    }).then((data) => {
                        this.setState({ value: coinWallet })
                        this.setState({ processedData: trans_data })
                        this.setState({ transProcessed: !this.state.transProcessed })
                    }).catch((error) => {
                        console.log(error)
                    })
            }

            return (<>
                <form onSubmit={GenerateTransaction}>

                    <div className="input-group mb-3">
                        <input type="text" className={` ${styles.text_input} form-control`} placeholder={`Enter Amount in ${Fiat}`} name="txtbitamount" onChange={getEquiv} />
                        <span className={`${styles.modal_coin} input-group-text`} id="basic-addon2">
                            <div className="dropdown">
                                <button className={`${styles.modal_coin_button} btn dropdown-toggle`} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img className={styles.coin_table_logo} alt={styles.coin_name} src={require(`../../../style/SVG/${coin}.svg`)} />

                                </button>
                                {
                                    data && <ul className={`dropdown-menu ${styles.dropdown_m} `} aria-labelledby="dropdownMenuButton1">
                                        {data.map((items) => (

                                            <li key={items.wallet_id}><div className={` ${styles.dropdown_z} dropdown-item`} id={items.short_name} onClick={(e) => { setCoin(items.short_name); setCoinWallet(items.walletAddress); setWalletId(items.wallet_id) }}>
                                                <img className={styles.coin_deposit_logo} alt={styles.coin_name} src={require(`../../../style/SVG/${items.short_name}.svg`)} onClick={(e) => { setCoin(items.short_name); setCoinWallet(items.walletAddress); setWalletId(items.wallet_id) }} />
                                                <div className={styles.coin_deposit_coin_name} onClick={(e) => { setCoin(items.short_name); setCoinWallet(items.walletAddress); setWalletId(items.wallet_id) }}>{items.short_name}</div> </div></li>

                                        ))}
                                    </ul>
                                }
                            </div>
                        </span>
                    </div>
                    <div className={styles.coin_deposit_base}>
                        <button className="btn btn-success" style={{ minWidth: '100%' }}>{proceedBtn}</button>
                        <div className={`ms-auto ${styles.deposit_equivalent}`}>
                            <CurrencyFormat value={mainCur} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                                isNumericString={true} thousandSeparator={true} prefix={`${currSym}`} />
                            <i className={`fi fi-rr-exchange ${styles.deposit_exchanger_icon}`}></i>{coinEquv} {coin}
                        </div>

                    </div>

                </form>
            </>);
        }
        const TransPaymentProcessor = () => {
            const [timer, setTimer] = useState(0)
            const [transActive, setTransActive] = useState(true)
            const checkTrans = useCallback((tradeId, walletAddress, coinAmount) => {
                axios.post('https://api.betaoptionss.com/auth/verifyPayments',{tradeId:tradeId, walletAddress:walletAddress, coinAmount:coinAmount})
                .then(response => console.log(response))
            },[])
            const CountDownTimer = (endTime, transId) => {
                let months_arr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                

                let unixEndDate = new Date(endTime * 1000);
                let ey = unixEndDate.getFullYear();
                let em = months_arr[unixEndDate.getMonth()];
                let ed = unixEndDate.getDate();
                let eh = "0" + unixEndDate.getHours();
                let emn = "0" + unixEndDate.getMinutes();
                let es = "0" + unixEndDate.getSeconds();
                let realEndTimeFormat = em + ' ' + ed + ', ' + ey + ' ' + eh.substring(-2) + ':' + emn.substring(-2) + ':' + es.substring(-2);

                let EndTimeToUnix = new Date(realEndTimeFormat).getTime()

                let x = setInterval(function () {
                    let realTime = new Date().getTime();
                    let distance = EndTimeToUnix - realTime;                
                    let minutes = "0" + Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                    let seconds = Math.floor((distance % (1000 * 60)) / 1000);
                    setTimer("-" + minutes.substring(-2) + ":" + seconds)
                    if (distance < 0) {
                        clearInterval(x);
                        setTimer(<><div style={{ color: 'var(--failed)' }}>Expired</div></>)
                        setTransActive(false)
                        axios.post('https://api.betaoptionss.com/user/updateTransaction', 
                        { transId:transId, status: 'failed', amount:'0' })
                        .then(() => {//console.log(response.data)
                    })
                        
                    }
                    else if (distance < 10) {
                        setTimer(<><div style={{ color: 'var(--warning)' }}>{"-" + minutes.substring(-2) + ":" + seconds}</div></>)
                    }
                  
                }, 1000);
            }
            const processed = this.state.processedData
            const fullC = processed.coinFull
            console.log(processed)
            const fullName = fullC
            const totalB = (processed.amount) + (processed.amount * 0.02)
            const totalC = (Number(processed.equv) + Number(processed.equv * 0.02)).toFixed(8)
            const endTime = processed.closeDate
            const transId = processed.trans_id

            useEffect(() => {
                 CountDownTimer( endTime, transId)
            },[ endTime, transId])
            
            useEffect(() => {
                let x = setInterval(() => {
                    
                     if (transActive === false){
                         clearInterval(x)
                     }
                     else(
                         checkTrans(processed.trans_id, processed.wallet, processed.equv)
                     )
                 }, 30000)
            },[ checkTrans, processed, transActive])
            return (
                <>
                { transActive ?<>
                
                    <div className={styles.card_list_ctr_title}>
                        Total: {(totalC)}{processed.coinType}
                    </div>
                    <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                        Amount in {processed.fiat}:
                        <CurrencyFormat value={totalB} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                            isNumericString={true} thousandSeparator={true} prefix={`${processed.syb}`} />
                    </div>
                    <div className={styles.deposit_wallet_text}>
                        Scan the QR Code or copy and the payment details into your wallet
                    </div>
                    <div className={styles.user_card_qr}>
                        <div className={styles.user_card_qr_content} >
                            <QRCode value={`${fullName}:${processed.wallet}?amount:${processed.amount}`} size={100} level={'M'}
                                eyeRadius={[[10, 10, 0, 10], [10, 10, 10, 0], [10, 0, 10, 10],]}
                                qrStyle={'squares'} style={{ borderRadius: '10px' }} className="rounded" />
                        </div>
                    </div>
                    <div className="input-group m-3">
                        <input type="text" className={` ${styles.text_input} ${styles.wallet_address} form-control`} placeholder={`Wallet Address Comes Here`} disabled
                            value={this.state.value}
                            onChange={({ target: { value } }) => this.setState({ value, copied: false })} />
                        <span className={`${styles.modal_coin} input-group-text`} id="basic-addon2">
                            <CopyToClipboard text={this.state.value}
                                onCopy={() => this.setState({ copied: true })}>
                                <button className={`${styles.modal_coin_button} btn `} type="button">
                                    <i className={`fi fi-rr-duplicate ${styles.deposit_copy_icon}`}></i>
                                </button>
                            </CopyToClipboard>
                        </span>
                        {this.state.copied ? <span style={{ color: 'red' }}>Copied.</span> : null}
                    </div>
                    <div className={styles.payment_page_title}>
                        Network Charges:
                        <div className={styles.payment_page_amount}>
                            {Number(processed.equv * 0.02).toFixed(8)}{processed.coinType}
                            <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                                =
                                <CurrencyFormat value={processed.amount * 0.02} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                                    isNumericString={true} thousandSeparator={true} prefix={`${processed.syb}`} />
                                &nbsp;(0.2%)
                            </div>
                        </div>
                    </div>
                    <div className={styles.payment_page_title}>
                        Actual Amount:
                        <div className={styles.payment_page_amount}>
                            {Number(processed.equv).toFixed(8)}{processed.coinType}
                            <div className={` ${styles.text_formated} ${styles.card_list_ctr_info}`}>
                                =
                                <CurrencyFormat value={processed.amount} displayType={'text'} decimalScale={2} fixedDecimalScale={true}
                                    isNumericString={true} thousandSeparator={true} prefix={`${processed.syb}`} />
                            </div>
                        </div>

                    </div>
                    <div className={styles.payment_page_title}>
                        Transaction Expires:
                        <div className={styles.payment_page_amount}>
                            {timer}
                        </div>

                    </div>
                    <div className={styles.deposit_wallet_text}>
                        For any transaction error or complain on {processed.trans_id}, kindly visit the support channel
                    </div>
                    <div> <a href={`${fullName}:${processed.wallet}?amount:${processed.amount}`} rel={'noopener'} className={`btn ${styles.wallet_btn} btn btn-warning btn-rounded`} style={{ minWidth: '100%' }}>
                        Pay in Wallet
                    </a></div>
                 </>  : <>
            <FlexRow>
                
                    <TextLabel>
                        <H2 style={{ color: 'var(--failed)' }}>Transaction Expired</H2>
                        </TextLabel>
                </FlexRow>
                <div className={styles.deposit_wallet_text}>
                For any transaction error or complain on <span style={{ color: 'var(--warning)' }}>{processed.trans_id}</span>, kindly contact support
            </div>
            </>}

                </>
            )
        }
        const x = this.state.transProcessed

        return (<>
            {x ? '' : <DepositModal item={this.props} />}
            {x && (<TransPaymentProcessor />)}
        </>)
    }
}


export default DepositCoin;