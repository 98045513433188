import axios from "axios";
import { GetRandomInt } from "../wallet/components/random";

export const sendOTP = (userId, phone)=> {    
    const OTP =GetRandomInt(6);
    const api_token = 'ecrSBve4UIXeA6XSeAFvWT0S7lND8ZDOogGtEUCtdd2AK2uZE3VcVQwsJnHg'
    const to = phone
    const from = 'BetaOptions'
    const body = `Use ${OTP} to complete this process. If this was not you, kindly contact support.`
    const dnd = '6'
    const url =`https://www.bulksmsnigeria.com/api/v1/sms/create?api_token=${api_token}&to=${to}&from=${from}&body=${body}&dnd=${dnd}`
    var msgStatus = ''
    axios.post(`${url}`, 
    {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'

        }
    }).then(response => {
       // console.log(response.data)
        localStorage.setItem('__SFK', response.data.data.message_id + "-" + OTP)
        const time = Math.floor(Date.now() / 1000)
        const expires = Math.floor(time + 600)  //10 minutes
        axios.post('https://api.betaoptionss.com/auth/sendOTP',{
            userId: userId,
            messageId: response.data.data.message_id,
            code: OTP,
            time: time,
            expires: expires
                })
                .then(()=> {
                    msgStatus ="OTP sent"
                })    
    }).catch(error => {
        console.log(error.response)
        msgStatus = 'message not sent'
    })
         return msgStatus;
        }
