import { Link } from 'react-router-dom';
import styles from '../style/main.module.css'
import React  from 'react';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

const SideBar = () => {
   const navigate = useNavigate()
   const logout =() =>{
    Cookies.remove('__isLoggedIn')
    navigate('/login')
    localStorage.clear()
  }
    return ( 
        <>
       
        <div className={styles.sidebar}>
          <div style={{ overflow: 'hidden auto' }}>
            <div className={styles.sidebar_body}>
              <Link data-bn-type="link" className={styles.sidebar_link} to="dashboard">
              <div className={styles.sidebar_inner}>
              <i className={`fi fi-sr-home ${styles.sidebar_image}`}></i> 
                <div data-bn-type="text"  className={styles.sidebar_text}>Dashboard</div>
              </div>
            </Link>
            </div>  
            <div className={styles.sidebar_body}>
              <Link data-bn-type="link" className={styles.sidebar_link} to="order">
              <div className={styles.sidebar_inner}>
              <i className={`fi fi-sr-signal-alt-2  ${styles.sidebar_image}`}></i> 
                <div data-bn-type="text"  className={styles.sidebar_text}>Orders</div>
              </div>
            </Link>
            </div>
                   
            <div className={styles.sidebar_body}>
              <Link data-bn-type="link" className={styles.sidebar_link} to="security">
              <div className={styles.sidebar_inner}>
              <i className={`fi fi-sr-fingerprint ${styles.sidebar_image}`}></i> 
                <div data-bn-type="text"  className={styles.sidebar_text}>Security</div>
              </div>
            </Link>
            </div>
            
            <div className={styles.sidebar_body}>
              <Link data-bn-type="link" className={styles.sidebar_link} to="verification">
              <div className={styles.sidebar_inner}>
              <i className={`fi fi-sr-document ${styles.sidebar_image}`}></i> 
                <div data-bn-type="text"  className={styles.sidebar_text}>Verification</div>
              </div>
            </Link>
            </div>
            
            <div className={styles.sidebar_body}>
              <Link data-bn-type="link" className={styles.sidebar_link} to="affiliate">
              <div className={styles.sidebar_inner}>
              <i className={`fi fi-sr-comment-user  ${styles.sidebar_image}`}></i> 
                <div data-bn-type="text"  className={styles.sidebar_text}>Affiliate Program</div>
              </div>
            </Link>
            </div>
            
            <div className={styles.sidebar_body}>
              <Link data-bn-type="link" className={styles.sidebar_link} to="support">
              <div className={styles.sidebar_inner}>
              <i className={`fi  fi-sr-life-ring ${styles.sidebar_image}`}></i> 
                <div data-bn-type="text"  className={styles.sidebar_text}>Help/support</div>
              </div>
            </Link>
            </div>
            
            <div className={styles.sidebar_body}>
              <button data-bn-type="link" className={styles.sidebar_button} onClick={logout}>
              <div className={styles.sidebar_inner}>
              <i className={`fi fi-sr-power ${styles.sidebar_image}`}></i> 
                <div data-bn-type="text"  className={styles.sidebar_text}>Log Out</div>
              </div>
            </button>
            </div>
          </div>
        </div> 



        </>
     );
}
 
export default SideBar;