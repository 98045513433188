import React, { useEffect, useState } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import styles from '../style/main.module.css'
import {Clearfix, GreenButton, Upload, UploadFile, UploadLabel, YellowButton } from './verification/verificationComponent';
import {  FlexColumn,  TextList, TextListIcon, TextListItem, CardContent,  TextLabel, DisplayHalf, H3, H4, ListHeading, ListBody, FlexRow, IDtype,  MiniIcon } from './wallet/components/bodyStyle';
import axios from 'axios'

class Verification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
    countrySelected:false,
    processedUserData:[],
    country: '',
    userId:""
}
    }


    render() {     
     const PersonlInfo = () => { 
         const processCurrency = (e) =>{
            e.preventDefault();
            if (country !== '' ){
                setVerifiedStatus(true);
            }
        }
        const processCardUpload =(e) =>{
            e.preventDefault();
              setSaveImageBtn(<><Clearfix></Clearfix><YellowButton disabled><div className="lds-ring"><div></div><div></div><div></div><div></div></div></YellowButton></>)
                    let UF = document.getElementById("UF");
                    let ft_side = document.getElementById("ft_side");
                    let UB = document.getElementById("UB");
                    let bk_side = document.getElementById("bk_side");
                  var cardFront = document.getElementById("upload-photo-front");
                  var cardBack = document.getElementById("upload-photo-back");
           

        
                    var fileF = cardFront.files;
                     var fileB = cardBack.files;
                var formData = new FormData();
                var file1 = fileF[0]; 
                var file2 = fileB[0]; 
                
                 if (!file1.type.match('image.*')) {
                    setUploadStatus('One or both file(s) selected is not an image.');
                    UF.style.backgroundColor = "red";
                     UF.style.pointerEvents = 'auto';
                    ft_side.innerHTML = "Re-Upload Front-side Document";
                    setIsUploadedFront(false);
                    setSaveImageBtn(<><Clearfix></Clearfix> <YellowButton onClick={processCardUpload}>Save</YellowButton></>)
                return;
                    }
                 if (!file2.type.match('image.*')) {
                setUploadStatus('One or both file(s) selected is not an image.');
                    UB.style.backgroundColor = "red";
                     UB.style.pointerEvents = 'auto';
                    bk_side.innerHTML = "Re-Upload Back-side Document";
                    setIsUploadedBack(false);
                    setSaveImageBtn(<><Clearfix></Clearfix> <YellowButton onClick={processCardUpload}>Save</YellowButton></>)
                return;
                    }
                

                    const userInfo = JSON.parse(localStorage.getItem('_currentInfo'))
                    const userId = userInfo.user_id

                 formData.append('upload-photo-front', file1, file1.name);
                 formData.append('upload-photo-back', file2, file2.name);
                 axios.post('https://api.betaoptionss.com/document/verificationUpload', formData,{
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    console.log (response);

                    axios.post('https://api.betaoptionss.com/document/updateImageStatus',
                    {userId:userId, 
                     imageBack:response.data.backName,
                    imageFront:response.data.frontName})
                        .then(response => {
                            axios.post('https://api.betaoptionss.com/user/updateUserStatus', { userId: userId, status: 2 })
                                .then(response => {
                                    setUploadStatus('uploaded successfully')
                                    const updateInfo = {
                                        country: userInfo.country,
                                        currency_id: userInfo.currency_id,
                                        demo: userInfo.demo,
                                        deposit: userInfo.deposit,
                                        email: userInfo.email,
                                        firstName: userInfo.firstName,
                                        lastLog: userInfo.lastLog,
                                        phone: userInfo.phone,
                                        lastName: userInfo.lastName,
                                        user_id: userInfo.user_id,
                                        verificationStatus: "2"
                                    }
                                    localStorage.setItem('_currentInfo', JSON.stringify(updateInfo))
                                    console.log(response);
                                    setSaveImageBtn(<><Clearfix></Clearfix> <GreenButton><i className="fi fi-sr-cloud-check"></i></GreenButton></>)


                                })
                                .catch(error => {
                                    console.log(error.response);
                                })
                        })
                        .catch(error=>{
                            console.log(error.response)
                        })
                   
                })
                .catch(err => {
                    console.log (err.response);
                })
        }

        const [uploadStatus, setUploadStatus] = useState(false);
        const [isUploadedFront, setIsUploadedFront]= useState(false);
        const [isUploadedBack, setIsUploadedBack]= useState(false);
        const [saveImageBtn, setSaveImageBtn]= useState(<><Clearfix></Clearfix> <YellowButton onClick={processCardUpload}>Save</YellowButton></>);
        const frontValue =(e)=>{
            let UF = document.getElementById("UF");
            let ft_side = document.getElementById("ft_side");
            if (e.target.value !== ""){
            UF.style.backgroundColor = "green";
            UF.style.pointerEvents = 'none';
            UF.style.color = '#ffffff';
            ft_side.innerHTML="Image Saved";
            setIsUploadedFront(true)
            }
        }        
        const backValue =(e)=>{            
            let UB = document.getElementById("UB");
            let bk_side = document.getElementById("bk_side");
            if (e.target.value !== ""){
            UB.style.backgroundColor = "green";
            UB.style.pointerEvents = 'none';
            UB.style.color = '#ffffff';
            bk_side.innerHTML="Image Saved";
            setIsUploadedBack(true)
            }
        }        
        
        const [country, setCountry] = useState('')
        const [verifiedStatus, setVerifiedStatus] = useState()
        const [idType, setIdType] = useState(0)
        const [idName, setIdName] = useState()







        if (!verifiedStatus){
 return (<>
            <FlexColumn>
        <ListHeading>
        <H3>Select issuing country</H3>
            <H4>Please select the country that issued your ID.</H4>
        </ListHeading>
        <ListBody> 
            <CountryDropdown  className={styles.selectContainer} value={country} onChange={(val) => {setCountry(val); }} />   
        </ListBody>
        <YellowButton onClick={processCurrency}>Proceed</YellowButton>
           </FlexColumn>
    
    </>)

        }
        else{
            if (idType === 0){
                return (  <> <FlexColumn>
                  <ListHeading>
        <H3>Select ID type</H3>
            <H4>Kindly select your prefarred card type</H4>
        </ListHeading>
        <ListBody>
                <FlexColumn>
                    <IDtype role="button" onClick={(e)=>{setIdType(1); setIdName('Drivers Lincense')}} focus-visible='false'>
                    
                    <TextLabel>Drivers Lincense</TextLabel>
                    <TextListIcon>
                       <MiniIcon src={ require(`../style/icons8/icons8-driver-license-96.png`) }/>
                       </TextListIcon>
                    </IDtype>
                    <IDtype role="button" onClick={(e)=>{setIdType(2); setIdName('State Id Card')}} focus-visible='false'>
                    
                    <TextLabel>State ID Card</TextLabel>
                    <TextListIcon>
                       <MiniIcon src={ require(`../style/icons8/icons8-identification-documents-96.png`) }/>
                       </TextListIcon>
                    </IDtype>
                    <IDtype role="button" onClick={(e)=>{setIdType(3); setIdName('International Passport')}} focus-visible='false'>
                   
                    <TextLabel>International Passport</TextLabel> 
                    <TextListIcon>
                       <MiniIcon src={ require(`../style/icons8/icons8-passport-96.png`) }/>
                       </TextListIcon>
                    </IDtype>
                    </FlexColumn>
        </ListBody>
       
                </FlexColumn></>)
            }
            else{
                return (<>
                <FlexColumn>
                <ListHeading>
        <H3>{idName}</H3>
            <H4>Kindly Snap the front side of your {idName}</H4>
            <Clearfix></Clearfix>
            <FlexRow>
                  <Upload  id="UF">
            <UploadLabel htmlFor="upload-photo-front"  id="ft_side">Document Front-side Upload</UploadLabel>
            <UploadFile type="file" id="upload-photo-front" capture="user" accept="image/*"  onChange={frontValue}/>
            </Upload>
            <Upload  id="UB">
            <UploadLabel htmlFor="upload-photo-back"  id="bk_side">Document Back-side Upload</UploadLabel>
            <UploadFile type="file" id="upload-photo-back" capture="user" accept="image/*"  onChange={backValue}/>
            </Upload>
            </FlexRow>
            { isUploadedFront && isUploadedBack ?  saveImageBtn : ""}
        </ListHeading>
        
                </FlexColumn>
                </>)
            }
            
        }

}
//const x = this.state.countrySelected
const DashboardVerificatiion = () => {
    const [verified, setVerified] = useState()
    const [proceedLevel2, setProceedLevel2] = useState(<><FlexRow><i className="fi fi-sr-lock"> </i> <TextListItem>Level 1 Verification Required</TextListItem></FlexRow></>) 
    const [proceedBtn, setProceedBtn] = useState(  <YellowButton data-bs-toggle="modal" data-bs-target="#AccountVerification">Start Verification</YellowButton>)
useEffect(()=>{
  const userInfo = localStorage.getItem('_currentInfo')
    const users = JSON.parse(userInfo)
    if (users.verificationStatus === "0") {
        setVerified('Invalid user')
    } else if (users.verificationStatus === "1") {
        setVerified('Unverified')
    } else if (users.verificationStatus === "2" || users.verificationStatus === "3") {
        setVerified('Under Review')
        setProceedBtn(<YellowButton><i className="fi fi-sr-stopwatch"></i></YellowButton>)
    } else if (users.verificationStatus === "4") {
        setVerified('Verified')
        setProceedBtn(<GreenButton><i className="fi fi-sr-cloud-check"></i></GreenButton>)
        setProceedLevel2(<YellowButton>Start Verification</YellowButton>)
    }
    },[]) 
   
    return (  <>
    
    <div className={styles.main_container}>
    <div className={styles.dashboard_welcome}>
                <div className={styles.dashboard_welcome_inner}>
                    <div className={styles.dashboard_welcome_content}>
                        <div className={`h2 ${styles.heading_text}`}>
                            Identity Verification
                        </div>
                    </div> 
                </div>
    </div>
    <div className={styles.dashboard_table}>
    <DisplayHalf className={`${styles.card} `}>
                <div className={styles.card_inner}>
                    <div className={styles.card_content}>
                       <div className={styles.card_header}>
                       <div className={styles.coin_id}>
                            <div className={` ${styles.balance} ${styles.coin_title} h5`}>User Verification</div> 
                            <div  className={styles.dashboard_welcome_verification}>
                            <i className="fi fi-sr-file-delete"></i> {verified}</div>                            
                            </div>
                            </div> 
                            <div className={styles.card_body}>
                                <CardContent>
                                        
                                             <FlexColumn>
                                        <TextList>
                                            <TextListIcon>
                                                <i className={` fi fi-sr-address-book ${styles.balance}`}></i>
                                            </TextListIcon>
                                            
                                            <TextListItem>Personal information</TextListItem>
                                        </TextList>
                                        <TextList>
                                            <TextListIcon>
                                                <i className={` fi fi-sr-id-badge ${styles.balance}`}></i>
                                            </TextListIcon>
                                            
                                            <TextListItem>Government-issued ID</TextListItem>
                                        </TextList>
                                        <TextList>
                                            <TextListIcon>
                                                <i className={` fi fi-sr-mode-portrait ${styles.balance}`}></i>
                                            </TextListIcon>
                                            
                                            <TextListItem>Facial recognition</TextListItem>
                                        </TextList>
                                       
                                        </FlexColumn> 
                                        <Clearfix/> 
                                       {proceedBtn}
                                        
                                       
                                       
                            {/* <AccountBalance id={userId} currency={currencySymbols} fiat={currency} /> */}
                            </CardContent>
                            </div>
                    </div>
                </div>
            </DisplayHalf>
    <DisplayHalf className={`${styles.card}`}>
                <div className={styles.card_inner}>
                    <div className={styles.card_content}>
                       <div className={styles.card_header}>
                       <div className={styles.coin_id}>
                            <div className={` ${styles.balance} ${styles.coin_title} h5`}>User Verification Pro</div> 
                            <div className={`  ${styles.coin_changes}`}  style={{direction: "ltr"}}> </div>
                            </div>
                            </div> 
                            <div className={styles.card_body}>
                                <div className={styles.balance_body}>
                                <CardContent>
                                        <FlexColumn>
                                   <TextList>
                                       <TextListIcon>
                                           <i className={`fi fi-sr-home-location ${styles.balance}`}></i>
                                       </TextListIcon>
                                       
                                       <TextListItem>Proof of Address</TextListItem>
                                   </TextList>
                                   <TextList>
                                       <TextListIcon>
                                           <i className={` fi fi-sr-glasses ${styles.balance}`}></i>
                                       </TextListIcon>
                                       
                                       <TextListItem>Government-issued SSN or its equivalent</TextListItem>
                                   </TextList>
                                  
                                   </FlexColumn> 
                                   <Clearfix/> 
                                  {proceedLevel2}
                                </CardContent>
                            </div>
                        </div>
                    </div>
                </div>
            </DisplayHalf>
           
        </div>

    </div>
<div className="modal fade" id="AccountVerification" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className= "modal-dialog modal-md">
    <div className={`  ${styles.card_inner} modal-content `}>
    <div className={`${styles.card_header} modal-header`}>
        <div className={`  ${styles.card_header_text}`} id="AccountVerificationLabel">{/*modalType*/}Verify your Identity</div>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div> 
      <div className={`${styles.card_body} modal-body`}>        
          
          <PersonlInfo/>
      </div>
    </div>
  </div>
</div>
    </>);
}  

       return (<>
       <DashboardVerificatiion/>
       </>)

    }
}

 
export default Verification;