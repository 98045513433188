import CurrencyFormat from 'react-currency-format';
import { Link } from 'react-router-dom';
import styles from '../css/index.module.css'
import { Half } from '../style/bodyStyle';
const IndexExchangeList = ({List}) => {
    
   const getChange = (change) => {
    if (change < 0){
        return (<> <div data-bn-type="text" className={` ${styles.sell} ${styles.coin_changes}`} style={{ direction: "ltr" }}>{change}%</div></>)
    }
    else if (change > 0){
        return (<> <div data-bn-type="text" className={` ${styles.buy} ${styles.coin_changes}`} style={{ direction: "ltr" }}>+{change}%</div></>)
    }
    else{
        return (<> <div data-bn-type="text" className={` ${styles.coin_changes}`} style={{ direction: "ltr" }}>{change}%</div></>)
    }
   
}
    if (List === ''){
        console.log("invalid")
    }
    
    return ( <>
        {List && List.map((item)=>(
          <Half key={`${item.base}/${item.cross}`} className={`${styles.card} ${styles.coin_card}`}>
            <div className={styles.card_inner}>
                <div className={styles.card_content}>
                    <div className={styles.card_header}>
                        <div className={styles.coin_id}>
                            <div className={styles.coin_title}>{`${item.base}`}</div>
                           {getChange(item.change)}
                        </div>
                    </div>
                    <div className={styles.card_body}>
                        <Link id="coin_1" className={styles.coin_ad} to="#">
                            <div data-bn-type="text" className={styles.coin_price}>
                            <CurrencyFormat value={item.price} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={item.symbol} /> </div>
                                    <div className={styles.coin_Market_cap_wrapper}>
                                        <div className={styles.coin_Market_cap}>Market Cap</div>
                            <div data-bn-type="text" className={styles.coin_equivalent}>
                            <CurrencyFormat value={item.marketCap} displayType={'text'} decimalScale={2} fixedDecimalScale={true} 
                                    isNumericString={true} thousandSeparator={true} prefix={item.symbol} /> 
                                    </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </Half>
    ))}
    </> );
}
 
export default IndexExchangeList;