import './css/font-awesome.min.css';
import './css/style.css'
import { useLocation, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import axios from 'axios';
import './css/loadProgressMin.css'
import React from 'react';
import AuthHeaders from './components/AuthHeader';
import { AuthContainer, AuthOTP, TextOTP, AuthTextFlex, AuthTextgroup, FlexColumn, ErrorText, ResendText, SuccessText } from './components/componentStyles';
import Page404 from './components/404';
import { GetRandomInt, GetRandomString } from '../market/pages/wallet/components/random';
import Cookies from 'js-cookie';
import { convertTime } from '../market/pages/wallet/components/getTime';


const LoginOTP = () => {
	var item = useLocation();
	document.title = `Beta Options | Login Authentication`;
	const d = new Date();
	let year = d.getFullYear();
	const [messageId, setMessageId] = useState()
	const navigate = useNavigate();


	const resendOTP = useCallback(() => {
		const user = item.state.user
		setResend(<><ResendText><div className="lds-ring"><div></div><div></div><div></div><div></div></div></ResendText></>)
		setOTP('')
		const OTP = GetRandomInt(6);
		axios.get(`https://api.betaoptionss.com/mailer/emailOTP?mailTo=${user.email}&token=${OTP}`).then(()=>{
						const time = Math.floor(Date.now() / 1000)
						const expires = Math.floor(time + 600)  //10 minutes
						const newMessageId = GetRandomString(36)
						
						axios.post('https://api.betaoptionss.com/auth/sendOTP',{
							userId: user.user_id,
							messageId: newMessageId,
							code: OTP,
							time: time,
							expires: expires
								})
								.then(()=> {
									setRegResponse(<><SuccessText>OTP sent</SuccessText></>)
									window.setTimeout(() => {setRegResponse('');
									setResend((<><ResendText>Didnt Recieve the Verification OTP? <TextOTP onClick={resendOTP}>Resend Code</TextOTP></ResendText></>))
								}, 10000);
									setMessageId(newMessageId)
									setResend("")
								}) 
						})
						.catch(err => {
							//console.log(err.response)
						})
	},[item])



	const [otp, setOTP] = useState('');
	const [logingBtn, setLoginBtn] = useState(<button className="btn btn-warning" type="submit" disabled={true}>Verify</button>);
	const [regResponse, setRegResponse] = useState('')
	const [resend, setResend] = useState(<ResendText>Didnt Recieve the Verification OTP? <TextOTP onClick={resendOTP}>Resend Code</TextOTP></ResendText>)



	const processOTP = 	useCallback(() => {
		const user = item.state.user
		setMessageId(item.state.messageId)
		if (otp.length === 6) {
			setRegResponse('')
			setResend('')
			setLoginBtn(<button className="btn btn-warning" type="submit" disabled={true}>
				<div className="lds-ring"><div></div><div></div><div></div><div></div></div></button>)
				axios.post('https://api.betaoptionss.com/auth/verifyOTP',{userId:user.user_id,messageId:messageId, code:otp})
				.then((response) => {
						Cookies.set('__isLoggedIn', true, { expires: 7 })
						localStorage.setItem('_currentInfo', JSON.stringify(user))
						axios.post('https://api.betaoptionss.com/user/getFiat',{currencyId:user.currency_id})
						.then((response)=>{
							const device = JSON.parse(localStorage.getItem('OMS__FEI'))
							const browser = device.client.name
							const browserType = device.device.type
							const osName = device.os.name
							const osVersion = device.os.version
							const osPlatform = device.os.platform
							const loginDevice= browser +" for " + browserType + " on " + osName + " " + osVersion + " " + osPlatform  
							const time = Math.floor(Date.now() / 1000)
							const timeDate = convertTime(time)
							axios.get(`https://api.betaoptionss.com/mailer/loginNotification?userMail=${user.email}&userId=${user.user_id}&device=${loginDevice}&time=${timeDate}`)
							.then(() => {//console.log(response)
							})
						  const curr =  response.data.currency
						  let sym = curr.symbol
						  let result = sym.substring(1, 2);
						  const items ={
							name: curr.name,
							symbol: result
						  }
						  localStorage.setItem('OMS__CUR', JSON.stringify(items))
						  navigate('/home/dashboard')
						})
						
				})
				.catch(() => {
					
					setLoginBtn(<button className="btn btn-warning" type="submit" disabled={true}>Verify</button>)
					setRegResponse(<><ErrorText>Invalid OTP Entred, Kindly request for another OTP if problem pesist</ErrorText></>);
						setResend((<><ResendText>Didnt Recieve the Verification OTP? <TextOTP onClick={resendOTP}>Resend Code</TextOTP></ResendText></>))
				})
		}
	}, [otp, item, messageId, navigate, resendOTP])

	useEffect(() => {
		if (otp.length === 6) {
			processOTP()
		}
	},[otp,processOTP ])



	if (item.state === null) {
		return (<>
			<Page404 />
		</>);
	}
	else {

		const user = item.state.user
		return (

			<>
				<AuthHeaders />
				<div className="wrapper">
					<div className="container-fluid">

						<AuthContainer>
							<div className="form-container">
								<div>

									<div className="row text-center">
										<h2 className="title-head hidden-xs">Login <span className='text-warning'>Verification</span></h2>
										<p className="info-form">Kindly enter the One-Time Password sent to {user.email}</p>
									</div>
									<form onSubmit={processOTP}>
										<div className='mt-10'>{regResponse}</div>

										<AuthTextgroup className="mb-4">
											<AuthTextFlex>
												<AuthOTP placeholder="Enter OTP" type="text" required value={otp} onChange={(e) => setOTP(e.target.value)} />
												
											</AuthTextFlex>
										</AuthTextgroup>
										<AuthTextgroup>
											<FlexColumn>
												{logingBtn}
											</FlexColumn>
										</AuthTextgroup>
										{resend}

									</form>

								</div>

							</div>
						</AuthContainer>
						<p className="text-center copyright-text">Copyright © {year} Beta Options All Rights Reserved</p>

					</div>


				</div>
			</>
		);
	}
}

export default LoginOTP;